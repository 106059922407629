import React from "react";
import image from "./img/img1home.jpg";
import image4 from "./img/image4.png";


const img = "./img/img1home.jpg";



function Home() {
  return (
    <div className="home">
      <div class="container">
        <div class="row align-items-center my-5">
          <div class="col-lg-7">
            <img
              class="img-fluid rounded mb-4 mb-lg-0"
              src= {image}
              alt=""
            />
          </div>
          <div class="col-lg-5">
            <h1 class="font-weight-bold">MAX-GPS</h1>
            <p>
              <h4>Наша компания занимается установкой спутникового оборудования для:</h4> 
              <li>таксопарков</li> 
              <li>транспортных компаний</li>
              <li>компаний по доставке товаров</li>
              <li>интернет магазины (маленькие, средние и крупные)</li>
              <li>устанавливаем также на личный транспорт и любые транспортные средства и спецтехнику</li>
            </p>
          </div>
        </div>
        <div class="col-lg-25">
            <img
              class="img-fluid rounded mb-4 mb-lg-0"
              src= {image4}
              alt=""
            />
          </div>
      </div>
    </div>
  );
}

export default Home;