import React from "react";
import image from "./img/image3.png"

function Price() {
  return (
    <div className="price">
      <div class="container">
        <div class="row align-items-center my-5">
          <div class="col-lg-7">
            <img
              class="img-fluid rounded mb-4 mb-lg-0"
              src= {image}
              alt=""
            />
          </div>
          <div class="col-lg-5">
            <h1 class="font-weight-light"></h1>
            
             <h3> Цены на наши услуги:</h3>
              <br>
              </br>
              <li>
              Трекер (спутниковое оборудование)  + установка от 4000 рублей (общая стоимость под ключ работа + оборудование)
              </li>
              <br>
              </br>
              <li>
              Трекер (спутниковое оборудование) + блокировка + установка от 4500 рублей (общая стоимость под ключ работа + оборудование)
              </li>
              <br>
              </br>
              <li>
              Трекер (спутниковое оборудование) + датчик уровня топлива от 15000 рублей (общая стоимость под ключ работа + оборудование)
              </li>
              <br>
              </br>
              <li>
              Трекер (спутниковое оборудование) + CAN по запросу
              <br>
              </br>
            </li>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Price;