import React from "react";
import logo from "./img/logo_brarus.png";
import logo2 from "./img/ggpro.png";
import logo3 from "./img/sellight.png";
import logo4 from "./img/christ.png";
import logo5 from "./img/stozel.png";
import logo6 from "./img/yakitor.png";

const Logo = "./img/logo_brarus.png";
const Logo2 = "./img/ggpro.png";
const Logo3 = "./img/sellight.png";
const Logo4 = "./img/christ.png";
const Logo5 = "./img/stozel.png";
const Logo6 = "./img/yakitor.png";



function Customers() {
  return (
    <div className="customers">
      <div class="container">
        <div class="row align-items-center my-5">
          <div class="col-lg-7">
            <img
              class="img-fluid mx-auto d-block rounded mb-4 mb-lg-0"
              src= {logo}
              alt=""
            />
          </div>
          <div class="col-lg-5">
            <h1 class="font-weight-bold">Наши клиенты</h1>
            <p>
              Все наши клиенты очень довольны работать с нами.<br>
              </br> Мы работаем с любыми компаниями, любых направлений. Для нас неважно, какое у Вас направление бизнеса. <br>
              </br> Мы всегда рады сотрудничеству и беремся за самые сложные проекты.<br></br> 
              Наш список партнеров говорит сам за себя, с нами сотрудничают крупнейшие сетевые компании.
            </p>
          </div>
        </div>
        <div class="row align-items-center my-5">
          <div class="col-lg-7">
            <img
              class="img-fluid rounded mb-4 mb-lg-0"
              src= {logo2}
              alt=""
            />
          </div>
          <div class="col-lg-5">
            <h1 class="font-weight-light"></h1>
            <p>
             
            </p>
          </div>
        </div>
        <div class="row align-items-center my-5">
          <div class="col-lg-7">
            <img
              class="img-fluid rounded mb-4 mb-lg-0"
              src= {logo3}
              alt=""
            />
          </div>
          <div class="col-lg-5">
            <h1 class="font-weight-light"></h1>
            <p>
              
            </p>
          </div>
        </div>
        <div class="row align-items-center my-5">
          <div class="col-lg-7">
            <img
              class="img-fluid mx-auto d-block rounded mb-4 mb-lg-0"
              src= {logo4}
              alt=""
            />
          </div>
          <div class="col-lg-5">
            <h1 class="font-weight-light"></h1>
            <p>
              
            </p>
          </div>
        </div>
        <div class="row align-items-center my-5">
          <div class="col-lg-7">
            <img
              class="img-fluid mx-auto d-block rounded mb-4 mb-lg-0"
              src= {logo5}
              alt=""
            />
          </div>
          <div class="col-lg-5">
            <h1 class="font-weight-light"></h1>
            <p>
              
            </p>
          </div>
        </div>
        <div class="row align-items-center my-5">
          <div class="col-lg-7">
            <img
              class="img-fluid mx-auto d-block rounded mb-4 mb-lg-0"
              src= {logo6}
              alt=""
            />
          </div>
          <div class="col-lg-5">
            <h1 class="font-weight-light"></h1>
            <p>
              
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Customers;