import React from "react";
import image from "./img/image10.jpg"

function Contact() {
  return (
    <div className="contact">
      <div class="container">
        <div class="row align-items-center my-5">
          <div class="col-lg-7">
            <img
              class="img-fluid rounded mb-4 mb-lg-0"
              src= {image}
              alt=""
            />
          </div>
          <div class="col-lg-5">
            <h1 class="font-weight-bold">Контакты</h1>
            <p>
              <h4>С нами можно связаться</h4> <br></br> 
              <br></br> 
             <li> тел: +79671991666</li> <br></br>
             <li>e-mail: max-gps@gmail.com</li> <br></br>
             <li>Адрес: г. Москва, ул. Волжский бульвар дом 1</li> <br></br>
             <li>WhatsApp: +79671991666</li> <br></br>
             <li>Telegram: @maxgps</li>

            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;