import React from "react";
import image from "./img/image7.jpg"
import image2 from "./img/image8.png"

const img2 = "./img/image7.jpg";

function About() {
  return (
    <div className="about">
      <div class="container">
        <div class="row align-items-center my-5">
          <div class="col-lg-6">
            <img
              class="img-fluid rounded mb-4 mb-lg-0"
              src= {image}
              alt=""
            />
            </div>
            <div class="col-lg-6">
            <img
              class="imf-fluid rounded mb-4 mb-lg-0" 
              src= {image2}
              alt=""
            />
            
            <h2>Работать с нами так просто, как на:</h2>
            <h4>На раз-два-три</h4>
            
            </div>
          
          <div class="col-lg-5">
            <h1 class="font-weight-bold">О нас</h1>
            <p>
              Наша компания занимается установкой спутникового оборудования. <br></br>
              Полностью весь цикл, от продажи оборудования, установка и последующее обслуживание. <br>
              </br>Нашими клиентами являются крупнейшие таксопарки Москвы и Московской области. <br>
              </br>Также мы работаем и с другими регионами.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;