import React, { Component} from "react";
import { Link, withRouter } from "react-router-dom";
import { Navbar, Nav, FormControl, Container, Form, Button, } from 'react-bootstrap'
import logo from "./img/image99.jpg"

export default class Navigation extends Component {
    render() {
        return (
            <Navbar collapseOnSelect expand="md">
            <Container>
            <Navbar.Brand href="/" >
            <img
                src= {logo}
                height="30"
                width="30"
                className="d-inline-block align-top"
                alt="Logo"
                
             />
             </Navbar.Brand>
             <Navbar.Toggle aria-controls="responsive-navbar-nav" />
             <Navbar.Collapse id="responsive-navbar-nav" >
                 <Nav className="mr-auto">
                    <Nav.Link href="/">Главная</Nav.Link>
                    <Nav.Link href="/About">О нас</Nav.Link>
                    <Nav.Link href="/Contact">Контакты</Nav.Link>
                    <Nav.Link href="/Customers">Наши клиенты</Nav.Link>
                    <Nav.Link href="/Price">Цены</Nav.Link>
                 </Nav>
                 <Form inline >
                     <FormControl
                        type="text"
                        placeholder="Search"
                        className="mr-sm-2"
                        />
                        <Button variant="outline-info">Search</Button>
                 </Form>
             </Navbar.Collapse>
             </Container>
             </Navbar>
        )
    }
}
